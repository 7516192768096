import React, { useState, useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode, Thumbs } from 'swiper/modules';
import { debounce } from 'lodash';
// import { sendMessageToSW } from '../serviceWorkerRegistration';



// import MapImageComponent from './MapImageComponent';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

const MapRenderer = (props) => {
    let city = props.city;
    let imgsArray = props.imagesArray;


    const [currentNode, setCurrentNode] = useState(city.id);
    const [currentNodeTitle, setCurrentNodeTitle] = useState(city.title);
    const [currentNodeImg, setCurrentNodeImg] = useState(city.image);
    const [lastImg, setLastImage] = useState("");
    const [imageIsLoading, setImageIsLoading] = useState(true);
    const [isLoadingId, setIsloadingId] = useState(null);
    const [currentNodePins, setCurrentNodePins] = useState(city.pins);

    const [productsPopupID, setProductsPopupID] = useState(null);
    const [currentNodeProductsTitle, setCurrentNodeProductsTitle] = useState("");
    const [productsList, setProductsList] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductTitle, setSelectedProductTitle] = useState(null);
    const [selectedProductSKU, setSelectedProductSKU] = useState(null);
    const [selectedProductShowCart, setSelectedProductShowCart] = useState(null);
    const [sentenceifthereisntAddtoCartEn, setSentenceifthereisntAddtoCartEn] = useState(null);
    const [sentenceifthereisntAddtoCartAr, setSentenceifthereisntAddtoCartAr] = useState(null);
    const [selectedProductImage, setSelectedProductImage] = useState(null);
    const [selectedProductDescription, setSelectedProductDescription] = useState(null);
    const [selectedProductDocuments, setSelectedProductDocuments] = useState(null);
    const [selectedProductSpecs, setSelectedProductSpecs] = useState(null);
    const [selectedProductRawHtml, setSelectedProductRawHtml] = useState(null);
    const [selectedProductMoreDetailsRawHtml, setSelectedProductMoreDetailsRawHtml] = useState(null);
    const [selectedProductThumbs, setSelectedProductThumbs] = useState([]);

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [needStretch, setNeedStretch] = useState(false);


    const [quantity, setQuantity] = useState(1);
    let maxAllowedQuantity = 100000000;

    const [cart, setCart] = useState([])
    const [itemToAddToCart, setItemToAddToCart] = useState(null);
    const [cartUpdated, setCartUpdated] = useState(false)

    const [previousPage, setPreviousPage] = useState(null);

    const [isZoomed, setIsZoomed] = useState(false);
    const [fullscreenThumbSrc, setFullscreenThumbSrc] = useState("");

    const [mapPinsVisible, setMapPinsVisible] = useState(false)
    const [activeImgId, setActiveImgId] = useState(false)

    const [totalImages, setTotalImages] = useState(0)
    const [loadedImages, setLoadedImages] = useState(0)
    const [loadedPercentage, setLoadedPercentage] = useState(0)

    const cartUpdatedPopupRef = useRef(null);

    const [translations, setTranslations] = useState(null);
    // const baseurl = 'https://napco.borninteractive.net/api';
    const baseurl = 'https://www.woosooqconnect.com:8990/api';

    var totalLoadedImages = 0;
    var totalArrayImages = 0;
    var totalLoadedPer = 0;

    const [backNavigation, setBackNavigation] = useState(false);


    const fetchTranslations = async () => {
        try {
            const response = await fetch(baseurl + '/app/Labels');
            const result = await response.json();
            // console.log('translations:', result.data.labels); // Log the response to inspect its structure

            setTranslations(result.data.labels);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleClickOutsideCartUpdatedPopup = (event) => {
        if (cartUpdatedPopupRef.current && !cartUpdatedPopupRef.current.contains(event.target)) {
            setCartUpdated(false);
        }
    }

    // const handleWindowResize = debounce(() => {
    //     handleImageLoad();
    // }, 300);

    useEffect(() => {

        window.onload = () => {
            setImageIsLoading(false)
        };
        document.addEventListener('DOMContentLoaded', cancelLoading);
        document.addEventListener('mousedown', handleClickOutsideCartUpdatedPopup);
        // window.addEventListener('resize', handleWindowResize);
        fetchTranslations();
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideCartUpdatedPopup);
            // document.removeEventListener('mousedown', handleWindowResize);

        };
    }, []);

    useEffect(() => {
        if (imgsArray.length != 0) {
            imgsArray.sort((a, b) => {
                // Extracting the level number from the IDs
                const getLevel = (id) => id.split('-').length - 1;

                const levelA = getLevel(a.id);
                const levelB = getLevel(b.id);

                // Compare the levels
                if (levelA === levelB) {
                    // If the levels are the same, compare the IDs directly
                    return a.id.localeCompare(b.id);
                } else {
                    // Otherwise, sort by level
                    return levelA - levelB;
                }
            });

            // console.log('images= ', imgsArray);
            // console.log('images count= ', imgsArray.length)
            setTotalImages(imgsArray.length)
            totalArrayImages = imgsArray.length;


            const images = document.querySelectorAll('.map');
            images.forEach(img => {
                // console.log('Processing image:', img.src, 'Complete:', img.complete);
                if (img.complete) {
                    handleImageLoad();
                } else {
                    img.onload = handleImageLoad;
                }
            });
        }
    }, [imgsArray]);

    useEffect(() => {

    }, [imgsArray]);

    const comparator = (a, b) => {
        // Function to check if an ID has two numbers separated by a hyphen
        const isTwoNumbersHyphen = (id) => /^\d+-\d+$/.test(id);

        const aIsTwoNumbersHyphen = isTwoNumbersHyphen(a.id);
        const bIsTwoNumbersHyphen = isTwoNumbersHyphen(b.id);

        if (aIsTwoNumbersHyphen && !bIsTwoNumbersHyphen) {
            return -1; // a comes before b
        } else if (!aIsTwoNumbersHyphen && bIsTwoNumbersHyphen) {
            return 1; // b comes before a
        } else {
            return 0; // no change in order
        }
    };

    const findNodeById = (nodes, id) => {
        if (nodes != null) {
            for (const node of nodes) {
                if (node.id === id) {
                    return node;
                }
                if (node.pins) {
                    const foundNode = findNodeById(node.pins, id);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
        }

        return null;
    };

    const findProductById = (products, id) => {
        let foundProduct = null;

        products.forEach((product) => {
            if (product.id === id) {
                foundProduct = product;
            }
        });

        if (foundProduct != null) {
            return foundProduct;
        } else {
            return null;
        }
    };

    const selectProduct = (id) => {
        setSelectedProduct(id)
    }

    const closeProductsPopup = () => {
        var previousNodeID = "";
        for (var i = 0; i < productsPopupID.split("-").length - 1; i++) {
            previousNodeID += productsPopupID.split("-")[i];
            if (i < productsPopupID.split("-").length - 2) {
                previousNodeID += "-"
            }
        }

        setCurrentNode(previousNodeID);

        const elements = document.getElementsByClassName('productsPopup')
        elements[0].classList.remove('fadein');
        setProductsPopupID(null);
        setSelectedProduct(null)
        setSelectedProductTitle(null)
        setSelectedProductDescription(null);
        setCartUpdated(false)
        setMapPinsVisible(true)
    }

    const goback = (currentpageid) => {
        var previousNodeID = "";
        for (var i = 0; i < currentpageid.split("-").length - 1; i++) {
            previousNodeID += currentpageid.split("-")[i];
            if (i < currentpageid.split("-").length - 2) {
                previousNodeID += "-"
            }
        }
        // console.log(previousNodeID);

        setCurrentNode(previousNodeID);
        setBackNavigation(true)


        var previoustwiceNodeID = "";
        for (var i = 0; i < previousNodeID.split("-").length - 1; i++) {
            previoustwiceNodeID += previousNodeID.split("-")[i];
            if (i < previousNodeID.split("-").length - 2) {
                previoustwiceNodeID += "-"
            }
        }
        let Prev = findNodeById(city.pins, previoustwiceNodeID);
        // console.log('previoustwiceNodeID', previoustwiceNodeID)
        // console.log('prev', Prev)
        if (Prev != null) {
            setPreviousPage(Prev.title)
        } else {
            if (localStorage.getItem('selectedLanguage') == 'ar') {
                setPreviousPage("المدينة")
            } else {
                setPreviousPage("City")
            }

        }

        setTimeout(() => {
            setMapPinsVisible(true)
            // alert(); 
        }, 3000)

    }

    const getCityMainNodeTitle = () => {
        if (localStorage.getItem('selectedLanguage') == 'ar') {
            return translations.cityAr
        } else {
            return translations.city
        }
    }

    const updateCurrentNode = (id, x, y, nestedpins) => {
        if (nestedpins != null) {
            makeZoomEffect(x, y)
            setTimeout(() => {
                setMapPinsVisible(false)
                // setIsloadingId(id)
                setPreviousPage(currentNodeTitle === "Main" ? getCityMainNodeTitle() : currentNodeTitle);
                setCurrentNode(id);
            }, 500)
        } else {
            setMapPinsVisible(false)
            // setIsloadingId(id)
            setPreviousPage(currentNodeTitle === "Main" ? getCityMainNodeTitle() : currentNodeTitle);
            setCurrentNode(id);
        }


    };

    const makeZoomEffect = (x, y) => {
        // alert(x + '% ' + y+ '% ')
        const mapWrapper = document.getElementById('mapWrapper');
        if (mapWrapper) {
            mapWrapper.style.transformOrigin = x + '% ' + y + '% ';
            mapWrapper.style.transform = 'scale(2.5)';
            mapWrapper.style.transition = 'transform 1s ease';
        }
    };

    const handleImageLoad = () => {
        // console.log('handleImageLoad called');
        var windowHeight = window.innerHeight;
        var windowWidth = window.innerWidth;
        var mapElement = document.getElementsByClassName('map')[0];//img
        var mapWrapper = document.getElementById('mapWrapper');

        localStorage.setItem('mapWrapperWidth', window.getComputedStyle(mapElement).getPropertyValue('width'));
        localStorage.setItem('mapWrapperHeight', window.getComputedStyle(mapElement).getPropertyValue('height'));

        mapWrapper.style.width = window.getComputedStyle(mapElement).getPropertyValue('width');

        // setImageIsLoading(false);
        // setIsloadingId(null);
        // setTimeout(() => {
        setMapPinsVisible(true);
        // console.log('done')

        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                left: (parseInt(window.getComputedStyle(mapElement).getPropertyValue('width')) / 2) - (windowWidth / 2)
            });
        }
        // }, 1500)

        totalLoadedImages++;
        // var prevLoadedImages = loadedImages + 1;
        // setLoadedImages(loadedImages + 1)
        // if(loadedid=='level0'){
        //     setImageIsLoading(false)
        // }

        // console.log(totalLoadedImages+"/"+totalArrayImages)

        totalLoadedPer = Math.floor((totalLoadedImages / 95) * 100);
        if (totalLoadedPer > 95) {
            setImageIsLoading(false)
        }
        if (document.getElementById('loaderValue')) {
            document.getElementById('loaderValue').innerHTML = totalLoadedPer > 100 ? "100" : totalLoadedPer + "%"
        }
    };

    // useEffect(() => {
    //     console.log('Loaded images effect triggered');

    //     var p = Math.floor((loadedImages / totalImages) * 100);

    //     if (isNaN(p)) {
    //         setLoadedPercentage(0)
    //     } else {
    //         setLoadedPercentage(p)
    //     }

    //     if (p > 95) {
    //         setImageIsLoading(false)
    //     }

    //     // console.log("images loaded " + loadedImages + "/" + totalImages)

    // }, [loadedImages]);

    const cancelLoading = () => {
        setImageIsLoading(false)
    }

    const moreQuantity = () => {
        if (quantity != maxAllowedQuantity) {
            setQuantity(quantity + 1);
        }
    }

    const lessQuantity = () => {
        if (quantity != 0) {
            setQuantity(quantity - 1);
        }
    }

    const refreshcart = () => {
        if (localStorage.getItem('cart')) {
            setCart(JSON.parse(localStorage.getItem('cart')));
        }
    }

    const addToCart = () => {
        let item = {
            id: selectedProduct,
            image: selectedProductImage,
            title: selectedProductTitle,
            sku: selectedProductSKU,
            quantity: quantity
        }

        setItemToAddToCart(item);
    }

    const goToCart = () => {
        closeProductsPopup()

        if (props.onGoToCart) {
            props.onGoToCart();
        }
    }

    React.useEffect(() => {
        const storedWidth = localStorage.getItem('mapWrapperWidth');
        if (storedWidth) {
            const myDiv = document.getElementById('mapWrapper');
            // myDiv.style.width = storedWidth;
        }

        if (localStorage.getItem('cart')) {
            setCart(JSON.parse(localStorage.getItem('cart')));
        }
    }, []);

    React.useEffect(() => {
        // console.log('jumpToNode', props.jumpToNode)
        if (props.jumpToNode && props.jumpToNode != null) {
            let teleport = props.jumpToNode;
            let teleportId = teleport.id;

            // if (teleport.products != null && teleport.products.length != 0 && teleport.pins == null && teleport.pins.length == 0) {
            if (teleport.sku) {

                let teleportType = "product";

                // console.log("0- teleportid=",teleportId)

                var previousNodeID = "";
                for (var i = 0; i < teleportId.split("-").length - 1; i++) {
                    previousNodeID += teleportId.split("-")[i];
                    if (i < teleportId.split("-").length - 2) {
                        previousNodeID += "-"
                    }
                }
                // console.log('1- nodecontainingproducts id '+previousNodeID)

                var previoustwiceNodeID = "";
                for (var i = 0; i < teleportId.split("-").length - 2; i++) {
                    previoustwiceNodeID += teleportId.split("-")[i];
                    if (i < teleportId.split("-").length - 3) {
                        previoustwiceNodeID += "-"
                    }
                }
                // console.log('2- twice id ' + previoustwiceNodeID)

                setCurrentNode(previoustwiceNodeID)

                let N = findNodeById(city.pins, previousNodeID);
                // let N= teleport;
                if (N !== null) {
                    setProductsPopupID(N.id)
                    setCurrentNodeProductsTitle(localStorage.getItem('selectedLanguage') == 'ar' ? N.titleAr : N.title)
                    setProductsList(N.products.filter(prod =>
                        prod.countries.some(country => country.value === localStorage.getItem('selectedCountryValue') || country.value === "All GCC")
                    ));

                    var previousThriceNodeID = "";
                    for (var i = 0; i < teleportId.split("-").length - 3; i++) {
                        previousThriceNodeID += teleportId.split("-")[i];
                        if (i < teleportId.split("-").length - 4) {
                            previousThriceNodeID += "-"
                        }
                    }



                    let R = findNodeById(city.pins, previousThriceNodeID);
                    // console.log(localStorage.getItem('selectedLanguage') == 'ar' ? R.titleAr : R.title)
                    setPreviousPage(localStorage.getItem('selectedLanguage') == 'ar' ? R.titleAr : R.title)
                }

                refreshcart();
                selectProduct(teleport.id);
                setTimeout(() => { setCartUpdated(false) }, 100)



            } else {
                let teleportType = "location";
                if (teleport.products != null && teleport.products != undefined && teleport.products.length != 0) {
                    var previousNodeID = "";
                    for (var i = 0; i < teleportId.split("-").length - 1; i++) {
                        previousNodeID += teleportId.split("-")[i];
                        if (i < teleportId.split("-").length - 2) {
                            previousNodeID += "-"
                        }
                    }

                    let Prev = findNodeById(city.pins, previousNodeID);

                    if (Prev != null) {
                        setPreviousPage(Prev.title)
                    } else {
                        setPreviousPage("City")
                    }
                    setCurrentNode(teleport.id)

                    setTimeout(() => {
                        var previousNodeID = "";
                        for (var i = 0; i < teleportId.split("-").length - 1; i++) {
                            previousNodeID += teleportId.split("-")[i];
                            if (i < teleportId.split("-").length - 2) {
                                previousNodeID += "-"
                            }
                        }
                        // console.log('this id', previousNodeID)
                    }, 10);


                } else {
                    setCurrentNode(teleportId)

                    var previousNodeID = "";
                    for (var i = 0; i < teleportId.split("-").length - 1; i++) {
                        previousNodeID += teleportId.split("-")[i];
                        if (i < teleportId.split("-").length - 2) {
                            previousNodeID += "-"
                        }
                    }
                    // console.log(previousNodeID)

                    let Prev = findNodeById(city.pins, previousNodeID);

                    if (Prev != null) {
                        setPreviousPage(Prev.title)
                    } else {
                        setPreviousPage("City")
                    }
                }

            }
        }

        setMapPinsVisible(true)
    }, [props.jumpToNode]);

    React.useEffect(() => {
        let N = findNodeById(city.pins, currentNode);

        if (N == null) {
            N = city;
            setPreviousPage(null)
        }

        if (N) {
            if (N.products) {
                setMapPinsVisible(true)
                setProductsPopupID(N.id)
                setCurrentNodeProductsTitle(localStorage.getItem('selectedLanguage') == 'ar' ? N.titleAr : N.title)

                // if (localStorage.getItem('selectedCountryValue') == 'All GCC') {
                //     console.log('isGCC= true', N)
                //     setProductsList(N.products);
                //     console.log('productsLIST= ', productsList)
                // } else {
                //     console.log('isGCC= false', N)
                setProductsList(N.products.filter(prod =>
                    prod.countries.some(country => country.value === localStorage.getItem('selectedCountryValue') || country.value === "All GCC")
                ));
                // console.log('productsLIST= ', productsList)
                // }



                var previousNodeID = "";
                for (var i = 0; i < currentNode.split("-").length - 2; i++) {
                    previousNodeID += currentNode.split("-")[i];
                    if (i < currentNode.split("-").length - 3) {
                        previousNodeID += "-"
                    }
                }

                let R = findNodeById(city.pins, previousNodeID);
                // console.log(localStorage.getItem('selectedLanguage') == 'ar' ? R.titleAr : R.title)
                setPreviousPage(localStorage.getItem('selectedLanguage') == 'ar' ? R.titleAr : R.title)

                var previousNodeID_product = "";
                for (var i = 0; i < currentNode.split("-").length - 1; i++) {
                    previousNodeID_product += currentNode.split("-")[i];
                    if (i < currentNode.split("-").length - 2) {
                        previousNodeID_product += "-"
                    }
                }

                setActiveImgId(previousNodeID_product)

            } else {
                setCurrentNodeTitle(localStorage.getItem('selectedLanguage') == 'ar' ? N.titleAr : N.title);
                setCurrentNodePins(N.pins);
                setMapPinsVisible(true)
                const mapWrapper = document.getElementById('mapWrapper');
                if (mapWrapper) {
                    mapWrapper.style.transformOrigin = '0% 0%';
                    mapWrapper.style.transform = 'scale(1)';
                    mapWrapper.style.transition = 'none';
                }

                // if (N.image != currentNodeImg) {
                // setLastImage(currentNodeImg)
                setCurrentNodeImg(N.image);
                setActiveImgId(currentNode)
                // setImageIsLoading(true);
                // setIsloadingId(N.id)
                // } else {
                // setImageIsLoading(false);
                // handleImageLoad()

                // const mapWrapper = document.getElementById('mapWrapper');
                // if (mapWrapper) {
                //     mapWrapper.style.transformOrigin = 'center';
                //     mapWrapper.style.transform = 'scale(1)';
                //     mapWrapper.style.transition = '';
                // }
                // }



                const selectedCountry = localStorage.getItem('selectedCountryValue');
                // if (selectedCountry != "All GCC") {
                N.pins.forEach(pin => {
                    if (pin.products != null) {
                        const filteredProducts = pin.products.filter(product => product.countries.some(country => country.value === selectedCountry || country.value === "All GCC"));
                        if (filteredProducts.length === 0) {
                            // hide the pin
                            // console.log(`Hiding pin ${pin.id} because it has no products in the selected country ${selectedCountry}`);
                            setTimeout(function () {
                                const element = document.getElementById(pin.id);
                                if (element) {
                                    element.classList.add('hidden');
                                }
                            }, 50);
                        } else {
                            // Remove the class if the pin should be visible
                            setTimeout(function () {
                                const element = document.getElementById(pin.id);
                                if (element) {
                                    element.classList.remove('hidden');
                                }
                            }, 50);
                        }
                    }
                });
                // }


            }
        }
    }, [currentNode]);

    React.useEffect(() => {
        if (selectedProduct != null) {
            let P = findProductById(productsList, selectedProduct);

            if (P) {
                // console.log('selectedProduct = ', P);
                setSelectedProduct(P.id)
                setSelectedProductTitle(localStorage.getItem('selectedLanguage') == 'ar' ? P.titleAr : P.title)
                setSelectedProductSKU(P.sku);
                setSelectedProductImage(P.image);
                setSelectedProductDescription(localStorage.getItem('selectedLanguage') == 'ar' ? P.descriptionAr : P.description);
                setSelectedProductDocuments([
                    {
                        "title": localStorage.getItem('selectedLanguage') == 'ar' ? translations.labTestsAr : translations.labTests,
                        "docurl": P.labTestsFile
                        // "docurl": P.labTestsFile == null ? null : P.labTestFile
                    }, {
                        "title": localStorage.getItem('selectedLanguage') == 'ar' ? translations.productsSheetAr : translations.productsSheet,
                        "docurl": P.productSheetsFile
                        // "docurl": P.productSheetsFile ? null : P.productSheetsFile
                    }, {
                        "title": localStorage.getItem('selectedLanguage') == 'ar' ? translations.msdsAr : translations.msds,
                        "docurl": P.msdsFile
                        // "docurl": P.msdsFile ? null : P.msdsFile
                    }]);
                setSelectedProductSpecs(P.specifications)
                // setSelectedProductRawHtml(localStorage.getItem('selectedLanguage') == 'ar' ? (P.longdescriptionAr == "" ? null : P.longdescriptionAr.split(/[*\n\t]/)) : (P.longdescriptionEn == "" ? null : P.longdescriptionEn.split(/[*\n\t]/)))
                setSelectedProductRawHtml(localStorage.getItem('selectedLanguage') == 'ar' ? (P.longdescriptionAr == "" ? null : P.longdescriptionAr) : (P.longdescriptionEn == "" ? null : P.longdescriptionEn))
                setSelectedProductMoreDetailsRawHtml(localStorage.getItem('selectedLanguage') == 'ar' ? (P.moreDetailsAr == "" ? null : P.moreDetailsAr) : (P.moreDetails == "" ? null : P.moreDetails))
                setSelectedProductThumbs(P.thumbs);
                setSelectedProductShowCart(P.showAddtoCartButton)
                setSentenceifthereisntAddtoCartEn(P.sentenceifthereisntAddtoCartEn)
                setSentenceifthereisntAddtoCartAr(P.sentenceifthereisntAddtoCartAr)

            } else {
                console.log('No products found with id ', selectedProduct);
            }
        } else {
            if (thumbsSwiper) {
                thumbsSwiper.destroy();
                setThumbsSwiper(null);
            }
        }
    }, [selectedProduct]);

    React.useEffect(() => {
        if (itemToAddToCart != null) {
            const foundProductIndex = cart.findIndex(product => product.id === itemToAddToCart.id);

            if (foundProductIndex !== -1) {
                const updatedCart = [...cart];
                updatedCart[foundProductIndex].quantity += itemToAddToCart.quantity;
                setCart(updatedCart);
            } else {
                setCart(prevCart => [...prevCart, itemToAddToCart]);
            }
        }
    }, [itemToAddToCart]);

    React.useEffect(() => {
        if (cart.length > 0) {
            localStorage.setItem('cart', JSON.stringify(cart));
            setCartUpdated(true)
        }
    }, [cart]);

    React.useEffect(() => {
        localStorage.setItem('backTo', JSON.stringify(previousPage));
    }, [previousPage]);

    const zoomImage = (thumb) => {
        setFullscreenThumbSrc(thumb)
        setIsZoomed(true);
    }

    const unzoomImage = () => {
        setFullscreenThumbSrc("");
        setIsZoomed(false);
    }

    // const productListPopup = useRef(null);

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutsideCart);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutsideCart);
    //     };
    // }, []);

    // const handleClickOutsideCart = (event) => {
    //     if (productListPopup.current && !productListPopup.current.contains(event.target)) {
    //         setQuantity(1);
    //         closeProductsPopup();
    //     }
    // }

    const downloadFile = (fileURL, filename) => {
        // console.log('Downloading file:', fileURL, filename);
        
        // const proxyUrl = 'https://corsproxy.io/?'; // Example CORS proxy 

        const fileUrl = fileURL.replaceAll(" ", "%20");

        // console.log("fileUrl", fileUrl);
        // fetch(fileUrl, {
        //       method: 'GET',
        //       headers: {
        //         'Content-Type': 'application/pdf',
        //       }
        //     })
        //       .then(response => {
        //         if (!response.ok) {
        //           throw new Error('Network response was not ok');
        //         }
        //         console.log('Response:', response);
                
        //         return response.blob();
        //       })
        //       .then(blob => {
        //         const url = window.URL.createObjectURL(new Blob([blob]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'file.pdf'); // or any other extension
        //         document.body.appendChild(link);
        //         link.click();
        //         link.parentNode.removeChild(link);
        //       })
        //       .catch(error => console.error('There was an error!', error));
    
        fetch(fileUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
              }
        }).then(response => {
            // console.log('Response:', response);
            // this should return a blob always
            return response.blob()})
            .then(blob => {
            const blobUrl = URL.createObjectURL(blob);
            // console.log('Blob URL:', blobUrl);
            

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = filename + '.pdf';
            link.click();

            URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
            console.error('Error downloading the file:', error);
        });
    };

    // this was solved from the BE side using the following code
    // app.UseStaticFiles(new StaticFileOptions
    //     {
    //         OnPrepareResponse = ctx =>
    //         {
    //             // Add CORS headers
    //             ctx.Context.Response.Headers.Append("Access-Control-Allow-Origin", "*");
    //             ctx.Context.Response.Headers.Append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    //             ctx.Context.Response.Headers.Append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");
         
    //             // Existing Cache-Control header
    //             ctx.Context.Response.Headers.Append("Cache-Control", $"public, max-age={cacheMaxAgeSixMonths}");
    //         },
    //         ContentTypeProvider = new FileExtensionContentTypeProvider
    //         {
    //             Mappings = { [".riv"] = "application/octet-stream" }
    //         }
    //     });
    

    const scrollContainerRef = useRef(null);
    useEffect(() => {

    }, []);

    const [isPopupVisible, setPopupVisible] = useState(false);


    useEffect(() => {
        if (productsPopupID != null) {
            setPopupVisible(true);
        } else {
            setPopupVisible(false);
        }
    }, [productsPopupID]);

    return (
        <div className="mapHolder">
            {previousPage != null &&
                <a onClick={() => { goback(currentNode) }} className="backbtn">{localStorage.getItem('selectedLanguage') == 'ar' ? translations.backToAr : translations.backTo} {previousPage}</a>
            }

            <h2 className="pageTitle">{currentNodeTitle == 'Main' ? '' : currentNodeTitle}</h2>

            <ScrollContainer mouseScroll={{ rubberBand: false }} innerRef={scrollContainerRef}>
                {/* <div> */}
                <div className="mapWrapper" id="mapWrapper">
                    {/* <img className={`map ${imageIsLoading ? 'loadingimg' : ''}`} src={currentNodeImg} alt="" onLoad={handleImageLoad} /> */}

                    {/* <MapImageComponent src={currentNodeImg} onLoaded={handleImageLoad} /> */}

                    {/* {imgsArray &&
                        <div className='allMapImages'>
                            {imgsArray.map((img, index) => (
                                <div key={index} id={`bgmapimg_${img.id}`} className={`noPointerEvents foreground ${activeImgId == img.id ? 'active' : ''}`} style={{ backgroundImage: `url(${img.url})` }}>
                                    <img className='map' src={img.url} alt={`image-${index}`} onLoad={() => { handleImageLoad(img.id) }} />
                                </div>
                            ))}
                        </div>
                    } */}

                    {imgsArray &&
                        <div className='allMapImages'>
                            {imgsArray.map((img, index) => (
                                <div key={index} id={`bgmapimg_${img.id}`} className={`noPointerEvents foreground ${activeImgId === img.id ? 'active' : ''}`} style={{ backgroundImage: `url(${img.url})` }}>
                                    {/* <img className='map' src={img.url} alt={`image-${index}`} data-id={img.id} /> */}
                                    <img className='map' src={img.url} alt={`image-${index}`} onLoad={() => handleImageLoad()} data-id={img.id} />

                                </div>
                            ))}
                        </div>
                    }

                    {currentNodePins?.map((pin) => (
                        pin?.products?.length == 0 ? <></> : (
                            <a key={pin.id} id={pin.id} onClick={() => { updateCurrentNode(pin.id, pin.x, pin.y, pin.pins); }} className={`pin ${mapPinsVisible && !imageIsLoading ? 'showpin' : ''} ${pin.products != null && pin.pins == null ? 'productPin' : ''}`} style={{ left: pin.x + "%", top: pin.y + "%", transitionDelay: Math.floor(Math.random() * 1001) + "ms, 0s" }}>
                                {localStorage.getItem('selectedLanguage') == 'ar' ? pin.titleAr : pin.title}
                            </a>
                        )
                    ))}

                    {/* {localStorage.getItem('selectedCountryValue') != "ALL GCC" && currentNodePins?.filter(pin =>
                        pin?.products?.length!=0 && pin?.products?.length == 0 && pin?.products?.some(product =>
                            product?.countries?.some(country =>
                                country.value === localStorage.getItem('selectedCountryValue')
                            )
                        )
                    ).map((pin) => (
                        <a key={pin.id} id={pin.id} onClick={() => { updateCurrentNode(pin.id, pin.x, pin.y, pin.pins); }} className={`pin ${mapPinsVisible && !imageIsLoading ? 'showpin' : ''} ${pin.products != null && pin.pins == null ? 'productPin' : ''}`} style={{ left: pin.x + "%", top: pin.y + "%", transitionDelay: Math.floor(Math.random() * 1001) + "ms, 0s" }}>
                            {localStorage.getItem('selectedLanguage') === 'ar' ? pin.titleAr : pin.title}
                        </a>
                    ))}

                    {localStorage.getItem('selectedCountryValue') != "ALL GCC" && pin?.products?.length==0 && currentNodePins?.map((pin) => (
                        <a key={pin.id} id={pin.id} onClick={() => { updateCurrentNode(pin.id, pin.x, pin.y, pin.pins); }} className={`pin ${mapPinsVisible && !imageIsLoading ? 'showpin' : ''} ${pin.products != null && pin.pins == null ? 'productPin' : ''}`} style={{ left: pin.x + "%", top: pin.y + "%", transitionDelay: Math.floor(Math.random() * 1001) + "ms, 0s" }}>
                            {localStorage.getItem('selectedLanguage') === 'ar' ? pin.titleAr : pin.title}
                        </a>
                    ))} */}



                </div>
                {/* </div> */}
            </ScrollContainer>


            <div className={`productsPopup ${isPopupVisible ? 'fadein' : ''}`}>
                {productsPopupID != null &&
                    <div className='content' >
                        <div className={`pophead ${selectedProduct ? 'align-flex-start' : ''}`}>
                            {selectedProduct && (
                                <div className="detailsHead">
                                    <a onClick={() => { setSelectedProduct(null); setQuantity(1); setCartUpdated(false) }} className="backtoProducts">{localStorage.getItem('selectedLanguage') == 'ar' ? translations.backToListAr : translations.backToList}</a>
                                    <div className='title'>{selectedProductTitle}</div>
                                    <div className='sku'>{selectedProductSKU}</div>
                                </div>
                            )}
                            {selectedProduct == null && (
                                <div className='title'>{currentNodeProductsTitle}</div>
                            )}
                            <a className='close' onClick={() => { setQuantity(1); closeProductsPopup() }}>

                                <svg version="1.1" id="closeButton" viewBox="0 0 21 21">
                                    <path className="closepath" strokeLinecap="round" d="M1,19.7L19.7,1" />
                                    <path id="hideonHover" strokeLinecap="round" className="closepath" d="M19.7,19.7L1,1" />
                                    <path className="closepath" strokeLinecap="round" d="M14.9,14.9L1,1" />
                                </svg>

                            </a>
                        </div>
                        <div className={`popbody ${selectedProduct ? 'smaller' : ''}`}>
                            {selectedProduct && (
                                <div className="detailsBody">
                                    <div className='d-flex'>
                                        <div className='col-md-6'>
                                            {selectedProductThumbs == null && <img src={selectedProductImage} onClick={() => { zoomImage(selectedProductImage) }} />}

                                            {selectedProductThumbs != null && selectedProductThumbs.length <= 1 && <img src={selectedProductImage} onClick={() => { zoomImage(selectedProductImage) }} />}

                                            {selectedProductThumbs != null && selectedProductThumbs.length > 1 &&
                                                <div className='swiperSlider'>
                                                    <Swiper
                                                        slidesPerView={1}
                                                        spaceBetween={0}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        modules={[Pagination, Thumbs]}
                                                        thumbs={{ swiper: thumbsSwiper }}
                                                        className="mySwiper"
                                                    >

                                                        {selectedProductThumbs.map((thumb, index) => (
                                                            <SwiperSlide key={index} >
                                                                <img className={`fullscreen-image ${isZoomed ? 'zoomed' : ''}`} src={thumb} alt={`Thumb ${index + 1}`} onClick={() => { zoomImage(thumb) }} />
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>

                                                    <Swiper
                                                        onSwiper={setThumbsSwiper}
                                                        spaceBetween={0}
                                                        slidesPerView={3}
                                                        freeMode={true}
                                                        // centeredSlides={true}
                                                        watchSlidesProgress={true}
                                                        modules={[Thumbs]}
                                                        className="thumbsSwiper"
                                                    >
                                                        {selectedProductThumbs.map((thumb, index) => (
                                                            <SwiperSlide key={index}>
                                                                <img src={thumb} alt={`Thumb ${index + 1}`} />
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            }

                                            {/* <div className='swiperSlider'>
                                                <Swiper
                                                    slidesPerView={1}
                                                    spaceBetween={0}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    modules={[Pagination, Thumbs]}
                                                    thumbs={{ swiper: thumbsSwiper }}
                                                    className="mySwiper"
                                                >

                                                    {selectedProductThumbs.map((thumb, index) => (
                                                        <SwiperSlide key={index}>
                                                            <img src={thumb} alt={`Thumb ${index + 1}`} />
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                                <Swiper
                                                    onSwiper={setThumbsSwiper}
                                                    spaceBetween={0}
                                                    slidesPerView={3}
                                                    freeMode={true}
                                                    // centeredSlides={true}
                                                    watchSlidesProgress={true}
                                                    modules={[Thumbs]}
                                                    className="thumbSwiper"
                                                >
                                                    {selectedProductThumbs.map((thumb, index) => (
                                                        <SwiperSlide key={index}>
                                                            <img src={thumb} alt={`Thumb ${index + 1}`} />
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>

                                            </div> */}
                                        </div>
                                        <div className='col-md-6'>
                                            <div className={'infoContainer ' + (localStorage.getItem('mode_isEcommerce') == 'true' ? '' : 'stretched')}>
                                                {/* {localStorage.getItem('selectedLanguage') == 'ar' ? (
                                                    <p>{selectedProductDescriptionAr == "" ? "" : selectedProductDescriptionAr}</p>
                                                ) : ( */}
                                                {/* <p>{selectedProductDescription == "" ? "" : selectedProductDescription}</p> */}

                                                {selectedProductDescription != null && selectedProductDescription != "" &&
                                                    <p>{selectedProductDescription}</p>
                                                }

                                                {/* {selectedProductDocuments[0].docurl != null && selectedProductDocuments[1].docurl != null && selectedProductDocuments[2].docurl != null && */}
                                                {/* <div className='documents'>
                                                    {selectedProductDocuments != null && selectedProductDocuments?.map((doc, index) => (
                                                        doc.docurl != null && (
                                                            <div key={index} className='documentWrapper'>
                                                                {localStorage.getItem('mode_isEcommerce') == "true" ? (
                                                                    <a className="document" onClick={() => { downloadFile(doc.docurl, doc.title) }}>{doc.title}</a>
                                                                ) : (
                                                                    <a className="document" onClick={() => { downloadFile(doc.docurl, doc.title) }}>{doc.title}</a>
                                                                )}

                                                            </div>
                                                        )
                                                    ))}
                                                </div> */}
                                                <div className='documents'>
                                                    {selectedProductDocuments != null && selectedProductDocuments?.map((doc, index) => (
                                                        doc.docurl != null && (
                                                            <div key={index} className='documentWrapper'>
                                                                {localStorage.getItem('mode_isEcommerce') == "true" ? (
                                                                    <a className="document" onClick={() => { downloadFile(doc.docurl, doc.title) }}>{doc.title}</a>
                                                                ) : (
                                                                    <a href={doc.docurl} target="_blank" className="document">
                                                                        {doc.title}
                                                                    </a>
                                                                )}

                                                            </div>
                                                        )
                                                    ))}
                                                </div>

                                                {selectedProductRawHtml != null &&
                                                    <div className='rawHTML'>
                                                        {/* {selectedProductRawHtml.slice(0, -1).map((liItem, index) => (
                                                            liItem.trim() !== "" && liItem.trim().length !== 0 && (

                                                                localStorage.getItem('selectedLanguage') == 'ar' ? (
                                                                    <b key={index}>{liItem.trim()}، </b>
                                                                ) : (
                                                                    <b key={index}>{liItem.trim()}, </b>
                                                                )

                                                            )
                                                        ))}
                                                        {selectedProductRawHtml[selectedProductRawHtml.length - 1] !== "" && (
                                                            <b>{selectedProductRawHtml[selectedProductRawHtml.length - 1].trim()}</b>
                                                        )} */}

                                                        {selectedProductRawHtml}
                                                        <br /><br />
                                                    </div>
                                                }

                                                <div className='specs'>
                                                    {selectedProductSpecs &&
                                                        <div>
                                                            {/* {localStorage.getItem('selectedLanguage') == 'ar' && selectedProductSpecs.titleAr != '' && selectedProductSpecs.titleAr != null &&
                                                                <>
                                                                    <b>{selectedProductSpecs.titleAr}</b>
                                                                    <br /><br />
                                                                </>
                                                            }
                                                            {localStorage.getItem('selectedLanguage') == 'en' && selectedProductSpecs.title != '' && selectedProductSpecs.title != null &&
                                                                <>
                                                                    <b>{selectedProductSpecs.title}</b>
                                                                    <br /><br />
                                                                </>
                                                            } */}
                                                            <ul>
                                                                {selectedProductSpecs?.map((liItem, index) => (
                                                                    <li key={index}>
                                                                        {liItem.label === "CartonParking" ? (
                                                                            localStorage.getItem('selectedLanguage') === 'ar' ? (
                                                                                <span>{translations.cartonPakingAr}:</span>
                                                                            ) : (
                                                                                <span>{translations.cartonPaking}:</span>
                                                                            )
                                                                        ) : (
                                                                            localStorage.getItem('selectedLanguage') === 'ar' ? (
                                                                                <span>{translations.unitPakingAr}:</span>
                                                                            ) : (
                                                                                <span>{translations.unitPaking}:</span>
                                                                            )
                                                                        )}
                                                                        {localStorage.getItem('selectedLanguage') === 'ar' ? liItem.nameAr : liItem.name}
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        </div>
                                                    }

                                                    {/* <ul>
                                                        <li>22 x 21.5 cm.</li>
                                                        <li>2 ply, strong and thick tissue.</li>
                                                        <li>Dependable strength and super absorbency.</li>
                                                        <li>Value for money product.</li>
                                                    </ul> */}

                                                </div>




                                                <div className='rawHTML moredetails' dangerouslySetInnerHTML={{ __html: selectedProductMoreDetailsRawHtml }}>

                                                </div>
                                            </div>
                                            {localStorage.getItem('mode_isEcommerce') == 'true' && selectedProductShowCart &&
                                                <div className='actions'>
                                                    <div className='quantity'>
                                                        <label>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.quantityLabelAr : translations.quantityLabel}</label>
                                                        <a className='btn less' onClick={() => { lessQuantity() }}></a>
                                                        <span className='number'>{quantity}</span>
                                                        <a className='btn more' onClick={() => { moreQuantity() }}></a>
                                                    </div>
                                                    <div className='cartAction'>
                                                        <a className='primButton addtocartbtn' onClick={() => { addToCart() }}>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.addtoCartBtnAr : translations.addtoCartBtn}</a>
                                                        <div ref={cartUpdatedPopupRef} className={`updatedCartMessage ${cartUpdated ? 'show' : ''}`}>
                                                            <span>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.prodAddedToCartAr : translations.prodAddedToCart}</span>
                                                            <div className='btns'>
                                                                <a className='primButton inverse' onClick={() => { setQuantity(1); goToCart(); setCartUpdated(false) }}>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.gotoCartBtnAr : translations.gotoCartBtn}</a>
                                                                <a className='primButton' onClick={() => { setSelectedProduct(null); setQuantity(1); setCartUpdated(false) }}>{localStorage.getItem('selectedLanguage') == 'ar' ? translations.continueBrowsingAr : translations.continueBrowsing}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            }

                                            {localStorage.getItem('mode_isEcommerce') == 'true' && !selectedProductShowCart &&
                                                <div className='actions'>
                                                    {localStorage.getItem('selectedLanguage') === 'en' ?
                                                        <span class="sentence">
                                                            {sentenceifthereisntAddtoCartEn}
                                                        </span>
                                                        :
                                                        <span class="sentence">
                                                            {sentenceifthereisntAddtoCartAr}
                                                        </span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedProduct == null && (
                                <div className="productsList">
                                    {productsList?.map((prod, index) => (
                                        <div key={index} id={prod.id} onClick={() => { refreshcart(); selectProduct(prod.id); setTimeout(() => { setCartUpdated(false) }, 1) }} className="product">
                                            <div className='productImage'>
                                                <img src={prod.image} alt="" />
                                            </div>
                                            <div className='productInfo'>
                                                <div className='productTitle'>{localStorage.getItem('selectedLanguage') == 'ar' ? prod.titleAr : prod.title}</div>
                                                <div className='productDescription'>{prod.sku}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>


            {fullscreenThumbSrc != "" &&
                <div className='fullscreenImgContainer' onClick={() => { unzoomImage() }} >
                    <img className='fullscreenthumb' src={fullscreenThumbSrc} />
                </div>
            }

            {imageIsLoading && localStorage.getItem('mode_isEcommerce') == "true" &&
                <div className='loader ecommerce transparentBg'>
                    <span id="loaderValue"></span>
                </div>
            }
            {imageIsLoading && localStorage.getItem('mode_isEcommerce') == "false" &&
                <div className='loader transparentBg'>
                    <span id="loaderValue"></span>
                </div>
            }
        </div >
    )
}

export default MapRenderer