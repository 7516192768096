import React, { useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Search from './Search';
import RiveAnimation from './riveAnimation';

const Header = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [searchIsExpanded, setSearchIsExpanded] = useState(false);
    const [languageIsOpen, setLanguageIsOpen] = useState(false);
    const [countryIsOpen, setCountryIsOpen] = useState(false);

    const [selectedCountryValue, setSelectedCountryValue] = useState("All GCC")
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage'))

    const [countries, setCountries] = useState([]);
    const langs = [{
        id: 1,
        name: 'English',
        nameAr: 'الانجليزية',
        value: 'en'
    }, {
        id: 2,
        name: 'Arabic',
        nameAr: 'العربية',
        value: 'ar'
    }
    ];

    // const baseurl = 'https://napco.borninteractive.net/api';
    const baseurl = 'https://www.woosooqconnect.com:8990/api';

    const location = useLocation();
    const { language, country } = queryString.parse(location.search);

    const [hideDropdowns, setHideDropdowns] = useState(false);
    const [clearFieldOrder, setClearFieldOrder] = useState(false);

    const [translations, setTranslations] = useState(null);

    const fetchTranslations = async () => {
        try {
            const response = await fetch(baseurl + '/app/Labels');
            const result = await response.json();

            setTranslations(result.data.labels);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    useEffect(() => {
        if (language && country) {
            localStorage.setItem('selectedCountryValue', country);
            localStorage.setItem('selectedLanguage', language);
            setSelectedCountryValue(country)
            setSelectedLanguage(language)
            setHideDropdowns(true)
            // console.log('got language and country params '+ language + country)
        } else {
            if (localStorage.getItem('selectedLanguage')) {
                setSelectedLanguage(localStorage.getItem('selectedLanguage'))
            } else {
                setSelectedLanguage('en')
            }

            if (localStorage.getItem('selectedCountryValue')) {
                setSelectedCountryValue(localStorage.getItem('selectedCountryValue'))
            } else {
                setSelectedCountryValue('All GCC')
            }

            setHideDropdowns(false)
        }
    }, [language, country]);

    useEffect(() => {
        if (false) {
            setCountries(null);
        } else {
            fetch(baseurl + '/app/countries')
                .then(response => response.json())
                .then(data => {
                    const countriesData = data.data.countries;
                    setCountries(countriesData);
                })
                .catch(error => console.error('Error fetching data:', error));
        }

        fetchTranslations();
    }, []);

    useEffect(() => {
        localStorage.setItem('selectedCountryValue', selectedCountryValue)
    }, [countries, selectedCountryValue]);
    useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage)
    }, [langs, selectedLanguage]);


    const [extractedCity, setExtractedCity] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [selectedPageId, setSelectedPageId] = useState(props.city.id);

    const menuDynamicBackgroundRef = useRef(null);
    const searchDynamicBackgroundRef = useRef(null);
    const languageDropdownRef = useRef(null);
    const countryDropdownRef = useRef(null);

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    }

    const toggleSearch = () => {
        setSearchIsOpen(!searchIsOpen);
    }

    useEffect(() => {
        if (menuIsOpen) {
            if (document.getElementsByClassName('swipeHint')[0].classList.contains('hide')) {

            } else {
                document.getElementsByClassName('swipeHint')[0].classList.add('hide')
            }

            if (document.getElementsByClassName('search')[0].classList.contains('raise')) {
            } else {
                document.getElementsByClassName('search')[0].classList.add('raise')
            }
        } else {
            document.getElementsByClassName('swipeHint')[0].classList.remove('hide')
            document.getElementsByClassName('search')[0].classList.remove('raise')
        }
    }, [menuIsOpen])

    useEffect(() => {
        if (searchIsOpen) {
            setClearFieldOrder(true)
            if (document.getElementsByClassName('swipeHint')[0].classList.contains('hide')) {

            } else {
                document.getElementsByClassName('swipeHint')[0].classList.add('hide')
            }
        } else {
            setClearFieldOrder(false)
            document.getElementsByClassName('swipeHint')[0].classList.remove('hide')
        }

    }, [searchIsOpen])

    useEffect(() => {
        if (languageIsOpen) {
            if (document.getElementsByClassName('swipeHint')[0].classList.contains('hide')) {

            } else {
                document.getElementsByClassName('swipeHint')[0].classList.add('hide')
            }
        } else {
            if (menuIsOpen) {
                document.getElementsByClassName('swipeHint')[0].classList.add('hide')
            } else {
                document.getElementsByClassName('swipeHint')[0].classList.remove('hide')
            }
        }

    }, [languageIsOpen])

    useEffect(() => {
        if (countryIsOpen) {
            if (document.getElementsByClassName('swipeHint')[0].classList.contains('hide')) {

            } else {
                document.getElementsByClassName('swipeHint')[0].classList.add('hide')
            }
        } else {
            if (menuIsOpen) {
                document.getElementsByClassName('swipeHint')[0].classList.add('hide')
            } else {
                document.getElementsByClassName('swipeHint')[0].classList.remove('hide')
            }
        }

    }, [countryIsOpen])

    const languageToggle = () => {
        setLanguageIsOpen(!languageIsOpen);
    }

    const countryToggle = () => {
        setCountryIsOpen(!countryIsOpen);
    }

    const extractIdAndTitle = (node) => {
        if (!node) {
            return null;
        }

        const { id, title, pins, products } = node;

        if (products) {
            return null; // Skip nodes with products
        }

        if (pins && pins.length > 0) {
            const extractedPins = pins.map((pin) => extractIdAndTitle(pin)).filter(Boolean);
            return { id, title: (id === "level0") ? "City" : title, pins: extractedPins };
        }

        return { id, title };
    };

    const renderNestedList = (data) => {
        if (!data) {
            return null;
        }

        const { id, title, pins } = data;

        if (pins && pins.length > 0) {
            const nestedItems = pins.map((pin) => renderNestedList(pin));
            return (
                <li key={id}>
                    <div>
                        <span className="title" onClick={() => updatePage(id)}>{title}</span>
                        {/* <span className="info">{pins.length} {pins.length==1?'pin':'pins'}{id}</span> */}
                        <span className="info">{id}</span>
                    </div>
                    <ul>{nestedItems}</ul>
                </li>
            );
        }

        return <li key={id}>
            <div>
                <span className="title" onClick={() => updatePage(id)}>{title}</span>
                {/* <span className="info">{pins.length} {pins.length==1?'pin':'pins'}{id}</span> */}
                <span className="info">{id}</span>
            </div>
        </li>;
    };

    const findNodeById = (nodes, id) => {
        for (const node of nodes) {
            if (node.id === id) {
                return node;
            }
            if (node.pins) {
                const foundNode = findNodeById(node.pins, id);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return null;
    };

    const updatePage = (id) => {
        if (findNodeById(props.city.pins, id) == null) {
            props.onSelectedPageChange("/largeMap.jpg");
        } else {
            props.onSelectedPageChange(findNodeById(props.city.pins, id).image)
        }
    }

    useEffect(() => {
        if (props.city) {
            setExtractedCity(extractIdAndTitle(props.city));
        }
    }, []);

    const handleClickOutsideMenu = (event) => {
        if (menuDynamicBackgroundRef.current && !menuDynamicBackgroundRef.current.contains(event.target)) {
            setMenuIsOpen(false);
            // document.getElementsByClassName('swipeHint')[0].classList.remove('hide')
            // alert();
        }
    }
    const handleClickOutsideSearch = (event) => {
        if (searchDynamicBackgroundRef.current && !searchDynamicBackgroundRef.current.contains(event.target)) {
            setSearchIsOpen(false);
            // document.getElementsByClassName('swipeHint')[0].classList.remove('hide')
            // alert('hi')
        }
    }
    const handleClickOutsideLanguage = (event) => {
        if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
            setLanguageIsOpen(false);
        }
    }
    const handleClickOutsideCountry = (event) => {
        if (countryDropdownRef.current && !countryDropdownRef.current.contains(event.target)) {
            setCountryIsOpen(false);
        }
    }

    const changeSelectedLanguage = (langvalue) => {
        if (langvalue != selectedLanguage) {
            // setLoading(true)
            // console.log(langvalue)
            setSelectedLanguage(langvalue)
            const url = new URL(window.location.href);
            // url.searchParams.set('cache', Date.now()); 
            // window.location.reload();
            window.location.href = url.href; 
        }
    }

    const changeSelectedCountry = (countryval) => {
        if (countryval != selectedCountryValue) {
            // setLoading(true)
            // console.log(countryval)
            setSelectedCountryValue(countryval)
            window.location.reload();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideMenu);
        document.addEventListener('mousedown', handleClickOutsideSearch);
        document.addEventListener('mousedown', handleClickOutsideLanguage);
        document.addEventListener('mousedown', handleClickOutsideCountry);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMenu);
            document.removeEventListener('mousedown', handleClickOutsideSearch);
            document.removeEventListener('mousedown', handleClickOutsideLanguage);
            document.removeEventListener('mousedown', handleClickOutsideCountry);
        };
    }, []);

    useEffect(() => {
        if (searchIsOpen) {
            document.getElementById('searchFieldInput').focus();
        }
    }, [searchIsOpen]);

    const handleCloseSuggestions = () => {
        setSearchIsExpanded(false);
    };
    const handleOpenSuggestions = () => {
        setSearchIsExpanded(true);
    };

    const handleJumpToNode = (node) => {
        setSearchIsOpen(false);
        props.sendToParentJumpToNode(node);
    };



    return (
        <header>
            <div className="left">

                <div ref={menuDynamicBackgroundRef} className={`menutoggle ${menuIsOpen ? 'open' : ''}`}>
                    {localStorage.getItem('mode_isEcommerce') != 'true' &&
                        <>
                            <div className='dynamicBackground'></div>
                            <a className="trigger" onClick={() => { toggleMenu() }}>
                                {/* <svg version="1.1" id="menuToggle" viewBox="0 0 24 24">
                                    <g id="menuToggle">
                                        <rect className="st0" width="24" height="24" />
                                        <path id="hovered" className="st1" d="M2,20.8h20 M2,11.4h20 M2,2h20" />
                                        <path id="" className="st1" d="M2,20.8h5.5 M2,11.4h20 M16,2h6" />
                                    </g>
                                </svg> */}

                                {/* <svg version="1.1" id="closeButton" viewBox="0 0 21 21">
                                    <path className="closepath" strokeLinecap="round" d="M1,19.7L19.7,1" />
                                    <path id="hideonHover" strokeLinecap="round" className="closepath" d="M19.7,19.7L1,1" />
                                    <path className="closepath" strokeLinecap="round" d="M14.9,14.9L1,1" />
                                </svg> */}

                                <RiveAnimation customClass="closeButtonCanvas" src="napco_close.riv" stateMachineName="State Machine 1" />
                                <RiveAnimation src="napco_menu_icon.riv" stateMachineName="State Machine 1" />

                            </a>

                            <div ref={menuDynamicBackgroundRef} className='menu'>
                                <ul>
                                    <li className='city'>
                                        {/* <a href="/">City</a> */}

                                        <Link onClick={() => {
                                            if (localStorage.backTo != "null") {
                                                handleJumpToNode(JSON.parse(localStorage.getItem('city')).data);
                                            }
                                            toggleMenu();
                                        }}>
                                            {localStorage.getItem('selectedLanguage') == 'ar' ? translations?.cityAr : translations?.city}
                                        </Link>
                                    </li>
                                    {/* <li className='help'>
                                <Link to="/help">{localStorage.getItem('selectedLanguage') == 'ar' ? translations?.helpAr : translations?.help}</Link>
                            </li> */}
                                </ul>

                                {!hideDropdowns && <ul>
                                    {langs != null &&
                                        <li className='language'>
                                            <span>{localStorage.getItem('selectedLanguage') == 'ar' ? translations?.languageLabelAr : translations?.languageLabel}</span>
                                            <ul id="langul" ref={languageDropdownRef} className={`dropdown ${languageIsOpen ? 'open' : ''}`} onClick={() => { languageToggle() }}>
                                                {/* <li className='selected'><a>English</a></li>
                                        <li style={{ display: languageIsOpen ? 'block' : 'none' }}><a>Arabic</a></li> */}

                                                {langs.map(lang => (
                                                    <li key={lang.id} className={lang.value === 'en' ? 'selected' : ''} style={{ display: lang.value === selectedLanguage || languageIsOpen ? 'block' : 'none' }} onClick={() => { changeSelectedLanguage(lang.value) }}>
                                                        <a>{localStorage.getItem('selectedLanguage') == 'ar' ? lang.nameAr : lang.name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    }
                                    {countries != null &&
                                        <li className='country'>
                                            <span>{localStorage.getItem('selectedLanguage') == 'ar' ? translations?.countryLabelAr : translations?.countryLabel}</span>
                                            <ul ref={countryDropdownRef} className={`dropdown ${countryIsOpen ? 'open' : ''}`} onClick={() => { countryToggle() }}>
                                                {/* <li className='selected'><a>KSA</a></li>
                                    <li style={{ display: countryIsOpen ? 'block' : 'none' }}><a>Qatar</a></li> */}

                                                {countries.map(country => (
                                                    <li key={country.id} className={country.value === 'All GCC' ? 'selected' : ''} style={{ display: country.value === selectedCountryValue || countryIsOpen ? 'block' : 'none' }} onClick={() => { changeSelectedCountry(country.value) }}>
                                                        <a>{localStorage.getItem('selectedLanguage') == 'ar' ? country.nameAr : country.name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    }
                                </ul>
                                }
                            </div>
                        </>
                    }
                </div>

            </div>

            <div className="logo" onClick={() => {
                if (localStorage.backTo != "null") {
                    handleJumpToNode(JSON.parse(localStorage.getItem('city')).data);
                }
            }}>
                {/* <img src={localStorage.getItem('logo')} alt="" className={localStorage.getItem('mode_isEcommerce') === 'true' ? 'ecommerce-logo' : ''} /> */}
                <img src={localStorage.getItem('logo')} alt="" className={localStorage.getItem('mode_isEcommerce') === 'true' ? '' : ''} />

            </div>

            <div className="right">
                {!props.isAdmin ? (
                    <div ref={searchDynamicBackgroundRef} className={`search ${searchIsOpen ? 'open' : ''} ${searchIsExpanded ? 'expanded' : ''}`}>
                        <div className='dynamicBackground'>
                            <Search jumptoNode={handleJumpToNode} closeSuggestions={handleCloseSuggestions} openSuggestions={handleOpenSuggestions} clearField={clearFieldOrder} />
                        </div>
                        <a className="trigger" onClick={() => { toggleSearch() }}>
                            {/* <svg version="1.1" id="searchSVG" viewBox="0 0 1919.5 1925">
                                <g>
                                    <circle id="glass" className="st0" cx="843.4" cy="846.1" r="720.9" />
                                    <path id="hover" className="st1" d="M1588.8,1763.6l-239.4-239.4c-46.8-46.8-46.8-122.5,0-169.3l0,0c46.8-46.8,122.5-46.8,169.3,0 l239.4,239.4c46.7,46.7,46.7,122.5,0,169.3l0,0C1711.4,1810.3,1635.6,1810.3,1588.8,1763.6z" />
                                    <path id="handle" className="st2" d="M1715.2,1890l-363.8-363.8c-46.7-46.7-46.7-122.5,0-169.3l0,0c46.7-46.7,122.5-46.7,169.3,0 l363.8,363.8c46.7,46.7,46.7,122.5,0,169.3l0,0C1837.7,1936.7,1762,1936.7,1715.2,1890z" />
                                </g>
                            </svg> */}

                            {/* <svg version="1.1" id="closeButton" viewBox="0 0 21 21">
                                <path className="closepath" strokeLinecap="round" d="M1,19.7L19.7,1" />
                                <path id="hideonHover" strokeLinecap="round" className="closepath" d="M19.7,19.7L1,1" />
                                <path className="closepath" strokeLinecap="round" d="M14.9,14.9L1,1" />
                            </svg> */}
                            
                            <RiveAnimation customClass="closeButtonCanvas" src="napco_close.riv" stateMachineName="State Machine 1" />
                            <RiveAnimation src="napco_search_icon.riv" stateMachineName="State Machine 1" />
                        </a>
                    </div>
                ) : <></>}
            </div>

            {loading &&
                <div className='loader'></div>
            }

        </header>
    )
}

export default Header