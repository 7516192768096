import React, { useState, useEffect, useRef } from "react";
import RiveAnimation from "./riveAnimation";

const Footer = (props) => {
  const [cartIsOpen, setCartIsOpen] = useState(false);
  const [cartIsEmpty, setCartIsEmpty] = useState(true);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);

  const dynamicBackgroundRef = useRef(null);

  let maxAllowedQuantity = 100000000;

  const toggleCart = () => {
    if (cartIsOpen) {
      setCartIsOpen(false);
    } else {
      if (localStorage.getItem("cart")) {
        setCart(JSON.parse(localStorage.getItem("cart")));
        setCartIsOpen(true);
      } else {
        localStorage.setItem("cart", cart);
        setCartIsOpen(true);
      }
    }
  };

  useEffect(() => {
    setLoading(false);
    if (cart != null && cart.length > 0) {
      setCartIsEmpty(false);
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      setCartIsEmpty(true);
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  useEffect(() => {
    if (props.opencart > 0) {
      if (localStorage.getItem("cart")) {
        setCart(JSON.parse(localStorage.getItem("cart")));
        setCartIsOpen(true);
      } else {
        localStorage.setItem("cart", cart);
        setCartIsOpen(true);
      }
    }
  }, [props]);

  const removeItemFromCart = (id) => {
    let x = findItemById(cart, id);

    if (x) {
      const updatedCart = cart.filter((item) => item.id !== id);
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      console.log("error deleting - no item found with id ", id);
    }
    // setCart(prevCart => {
    // const updatedCart = prevCart.filter(item => item.id !== id);
    // console.log(updatedCart);
    // return updatedCart;
    // });
  };

  const moreQuantity = (id) => {
    let x = findItemById(cart, id);
    let increaseBtn = document.querySelector(`#${id} .quantity .more`);
    let decreaseBtn = document.querySelector(`#${id} .quantity .less`);

    x.quantity = x.quantity + 1;
    updateQuantityInDOM(id, x.quantity);

    if (x.quantity == maxAllowedQuantity && increaseBtn && decreaseBtn) {
      increaseBtn.classList.add("disabled");
    }
    if (increaseBtn && decreaseBtn) {
      decreaseBtn.classList.remove("disabled");
    }
  };

  const lessQuantity = (id) => {
    let x = findItemById(cart, id);
    let increaseBtn = document.querySelector(`#${id} .quantity .more`);
    let decreaseBtn = document.querySelector(`#${id} .quantity .less`);

    x.quantity = x.quantity - 1;
    updateQuantityInDOM(id, x.quantity);

    if (x.quantity == 1 && increaseBtn && decreaseBtn) {
      decreaseBtn.classList.add("disabled");
    }

    if (increaseBtn && decreaseBtn) {
      increaseBtn.classList.remove("disabled");
    }
  };

  const updateQuantityInDOM = (id, quantity) => {
    const quantitySpan = document.querySelector(`#${id} .quantity .number`);

    if (quantitySpan) {
      quantitySpan.innerText = quantity;
    }

    const cart = JSON.parse(localStorage.getItem("cart"));
    if (!cart) {
      console.error("Cart is empty or not found in local storage");
      return;
    }

    // Find the item by id
    let item = findItemById(cart, id);
    if (item) {
      // Update the item's quantity
      item.quantity = quantity;

      // Save updated cart to local storage
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      console.log("Error updating quantity for item with id", id);
    }
  };

  const findItemById = (cart, id) => {
    for (const item of cart) {
      if (item.id === id) {
        return item;
      }
      // if (item.pins) {
      //     const foundNode = findItemById(node.pins, id);
      //     if (foundNode) {
      //         return foundNode;
      //     }
      // }
    }
    return null;
  };

  const handleClickOutsideCart = (event) => {
    if (
      dynamicBackgroundRef.current &&
      !dynamicBackgroundRef.current.contains(event.target)
    ) {
      setCartIsOpen(false);
    }
  };

  const [translations, setTranslations] = useState(null);
  // const baseurl = 'https://napco.borninteractive.net/api';
  const baseurl = "https://www.woosooqconnect.com:8990/api";

  const fetchTranslations = async () => {
    try {
      const response = await fetch(baseurl + "/app/Labels");
      const result = await response.json();

      setTranslations(result.data.labels);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("selectedLanguage") == "ar") {
    }
    document.addEventListener("mousedown", handleClickOutsideCart);

    fetchTranslations();
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCart);
    };
  }, []);

  const [showCheckout, setShowCheckout] = useState(true);
  const itemsArray = cart.map((item) => item.sku).join(",");
  const quantitiesArray = cart.map((item) => item.quantity).join(",");

  const checkout = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart"));

    //test URl
    // const apiUrl = 'https://napco-oic-frrqxvisthzf-fr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/UPDATE_CART_ITEMS/1.0/';
    //live URl
    const apiUrl =
      "https://napco-oic-prod-frrqxvisthzf-fr.integration.ocp.oraclecloud.com/ic/api/integration/v1/flows/rest/UPDATE_CART_ITEMS/1.0/";
    const apiUser = "web.integration";
    const apiPass = "N@pc0P@ss@2024";
    const apiAuth = "Basic";
    const apiBody = {
      profileid: localStorage.getItem("profileid"),
      orgid: localStorage.getItem("orgid"),
      siteid: localStorage.getItem("siteid"),
      cart: cartItems.map((item) => ({
        item: item.sku,
        Quantity: item.quantity,
      })),
    };

    // Constructing the request options
    const requestOptions = {
      method: "POST",
      // mode: 'no-cors',
      headers: {
        Authorization: `${apiAuth} ${btoa(`${apiUser}:${apiPass}`)}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiBody),
    };

    // console.log("Items:", itemsArray);
    // console.log("Quantities:", quantitiesArray);
    // // console.log('urltoredirect: ',document.getElementById('redirect').innerHTML)
    // console.log(
    //   "isEcommerce = : ",
    //   localStorage.getItem("mode_isEcommerce") == "true"
    // );
    // console.log(
    //   "id=",
    //   localStorage.getItem("profileid"),
    //   " and condition is ",
    //   localStorage.getItem("profileid") == "0000"
    // );

    setLoading(true);

    // Making the API call
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCartIsOpen(false);
        setCart(null);
        setCartIsEmpty(true);
        // window.top.location.href = 'http://www.commerce.napconational.com/confmsg';
        // window.parent.location.replace('http://www.commerce.napconational.com/confmsg');
        // window.parent.postMessage({ type: 'redirect', url: 'http://www.commerce.napconational.com/confmsg' }, '*');
        document.getElementById("redirect").click();

        setLoading(false);
        // if ('app' !== document.getElementByTagName('body').attr('id')) {

        // } else {
        // window.location.href = 'http://www.commerce.napconational.com/confmsg';
        // }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
        // Optionally set showCheckout based on error condition
      });
  };

  const redirectHandler = (cases) => {
    let hasRedirected = false; // Flag to track redirection

    if (document.getElementById("redirect")) {
      document
        .getElementById("redirect")
        .addEventListener("click", function (e) {
          e.preventDefault(); // Prevent default action
          if (hasRedirected) {
            return; // Prevent further action if already redirected
          }

          hasRedirected = true; // Set the flag to true on first click

          const referrer = document.referrer;

          let currentUrl = "";

            switch (referrer) {
            case "https://woosooq.com/":
            case "https://www.woosooq.com/":
              currentUrl = "https://www.woosooq.com";
              break;
            case "https://www.sa.woosooq.com/":
            case "https://sa.woosooq.com/":
              currentUrl = "https://www.sa.woosooq.com";
              break;
            case "https://www.bh.woosooq.com/":
            case "https://bh.woosooq.com/":
              currentUrl = "https://www.bh.woosooq.com";
              break;
            case "https://www.ae.woosooq.com/":
            case "https://ae.woosooq.com/":
              currentUrl = "https://www.ae.woosooq.com";
              break;
            default:
              currentUrl = "https://www.woosooq.com";
              break;
            }


          // const currentUrl = "https://www.woosooq.com/";
          // document.referrer === "https://woosooq.com/"
          //   ? "https://woosooq.com"
          //   : document.referrer === "https://www.woosooq.com/"
          //   ? "https://www.woosooq.com"
          //   : "";

          try {
            if (currentUrl === "") return; // Exit if current URL is not recognized
            if (cases === 1) {
              window.location.href = `${currentUrl}/confmsg?sku=${itemsArray}&qty=${quantitiesArray}&profileid=${localStorage.getItem(
                "profileid"
              )}`; // Attempt redirection
            } else {
              window.location.href = `${currentUrl}/confmsg`;
            }
          } catch (error) {
            console.error("Redirection failed:", error);
            hasRedirected = false; // Reset flag if redirection fails
          }
        });
    }
  };

  return (
    <footer>
      {localStorage.getItem("mode_isEcommerce") &&
      localStorage.getItem("profileid") == "0000" ? (
        //test
        // <a href={`https://l7820113c1dev-store.occa.ocs.oraclecloud.com/confmsg?sku=${itemsArray}&qty=${quantitiesArray}`} id="redirect" style={{ position: 'absolute', opacity: 0 }}></a>
        //live
        <a
          id="redirect"
          onClick={redirectHandler(1)}
          style={{ position: "absolute", opacity: 0 }}
        ></a>
      ) : (
        //test
        // <a href="https://l7820113c1dev-store.occa.ocs.oraclecloud.com/confmsg" id="redirect" style={{ position: 'absolute', opacity: 0 }}></a>
        //live
        <a
          id="redirect"
          onClick={redirectHandler(2)}
          style={{ position: "absolute", opacity: 0 }}
        ></a>
      )}

      <div className="footerPlaceHolder"></div>
      <div className="swipeHint">
        <span>
          {localStorage.getItem("selectedLanguage") == "ar"
            ? translations?.mapHintAr
            : translations?.mapHint}
        </span>
        {props.isAdmin ? (
          <span className="click">Click on the map to add a pin.</span>
        ) : (
          <></>
        )}
      </div>

      <div
        ref={dynamicBackgroundRef}
        className={`cartToggle ${cartIsOpen ? "open" : ""}`}
      >
        {localStorage.getItem("mode_isEcommerce") == "true" &&
        !props.isAdmin ? (
          <>
            <div ref={dynamicBackgroundRef} className="dynamicBackground">
              <div className="head">
                {localStorage.getItem("selectedLanguage") == "ar"
                  ? translations?.myCartAr
                  : translations?.myCart}
              </div>
              <div className="cartListing">
                {cartIsEmpty && (
                  <div className="empty">
                    <img src="./emptycart.svg" />
                    <p>
                      {localStorage.getItem("selectedLanguage") == "ar"
                        ? translations?.cartEmptyAr
                        : translations?.cartEmpty}
                    </p>
                  </div>
                )}
                {!cartIsEmpty && (
                  <div>
                    <ul className="items">
                      {cart?.map((item) => (
                        <li key={item.id} id={item.id} className="item">
                          <div className="itemImage">
                            <img src={item.image} />
                          </div>

                          <div className="itemInfo">
                            <div className="itemTitle">{item.title}</div>
                            <div className="itemSKU">{item.sku}</div>
                            <div className="itemFooter">
                              <div className="quantity">
                                <a
                                  className={`btn less ${
                                    item.quantity === 1 ? "disabled" : ""
                                  }`}
                                  onClick={() => {
                                    lessQuantity(item.id);
                                  }}
                                ></a>
                                <span className="number">{item.quantity}</span>
                                <a
                                  className={`btn more ${
                                    item.quantity === 10 ? "disabled" : ""
                                  }`}
                                  onClick={() => {
                                    moreQuantity(item.id);
                                  }}
                                ></a>
                              </div>
                              <a
                                className="remove"
                                onClick={() => removeItemFromCart(item.id)}
                              ></a>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <div className="checkoutHolder">
                      <a className="primButton" onClick={() => checkout()}>
                        {localStorage.getItem("selectedLanguage") == "ar"
                          ? translations?.checkoutBtnAr
                          : translations?.checkoutBtn}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <a
              className="trigger"
              onClick={() => {
                toggleCart();
              }}
            >
              {/* <svg version="1.1" id="cart" viewBox="0 0 27 27">
                                <path className="st0" d="M7.9,5.2h17.4L23,13.5H9.2 M24.1,18.2H10L7.6,2.9H4.1 M11.1,22.9c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2 c0-0.7,0.5-1.2,1.2-1.2C10.6,21.8,11.1,22.3,11.1,22.9z M24.1,22.9c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2 c0-0.7,0.5-1.2,1.2-1.2C23.6,21.8,24.1,22.3,24.1,22.9z" />
                                <g id="dashes">
                                    <line className="st0" x1="4.1" y1="8.8" x2="1.7" y2="8.8" />
                                    <line className="st0" x1="5.3" y1="12.3" x2="1.7" y2="12.3" />
                                    <line className="st0" x1="6.4" y1="15.9" x2="1.7" y2="15.9" />
                                </g>
                            </svg> */}

              {/* <svg version="1.1" id="closeButton" viewBox="0 0 21 21">
                                <path className="closepath" strokeLinecap="round" d="M1,19.7L19.7,1" />
                                <path id="hideonHover" strokeLinecap="round" className="closepath" d="M19.7,19.7L1,1" />
                                <path className="closepath" strokeLinecap="round" d="M14.9,14.9L1,1" />
                            </svg> */}
              <RiveAnimation
                customClass="closeButtonCanvas"
                src="napco_close.riv"
                stateMachineName="State Machine 1"
              />
              <RiveAnimation
                src="napco_cart_icon.riv"
                stateMachineName="State Machine 1"
              />
            </a>
          </>
        ) : (
          <></>
        )}
      </div>

      {loading && localStorage.getItem("mode_isEcommerce") == "true" && (
        <div className="loader ecommerce transparentBg">
          <span id="loaderValue"></span>
        </div>
      )}
      {loading && localStorage.getItem("mode_isEcommerce") == "false" && (
        <div className="loader transparentBg">
          <span id="loaderValue"></span>
        </div>
      )}
    </footer>
  );
};

export default Footer;
